@import "normalize-css";
@import "references/mixins";
@import "references/typography";
@import "references/variables";
@import "minigrid";
@import url("https://use.typekit.net/vto8ztj.css");
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

* {
  box-sizing: border-box;
}

html,
body {
  overflow-x: hidden;
}

html {
  font-size: 62.5%;
}
#root{
  height:100vh;
  display:flex;
  flex-direction: column;
}

.l-all{
  flex:1;
  position:relative;
  display: flex;
  flex-direction: column;
}
body {
  @include font-size(1.6, 2.4);
  background: linear-gradient(-45deg, #002e4d, #111);
  background-size: 200% 200%;
  height: 100vh;
  color: $c-white;
  font-family: $f-body;
  padding: 0;
}

footer {
  padding:10px 20px;
  opacity:0.5;
  border-top:1px solid rgba(255,255,255,0.2);
  text-align: center;
  display:flex;
  flex-wrap: wrap;
  justify-content: center;

  a{
    color:#fff;
  }

  ul {
    list-style: none;
    margin: 0 0 15px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    li {
      margin: 0 8px;

      a {
        display: block;


        &:hover {
          svg {
            fill: rgba(255, 255, 255, 0.6);
          }
        }

        svg {
          height: 20px;
          fill: $c-white;
          transition: all .3s ease-in-out;
          display:block;
        }
      }
    }
  }

  @media(min-width:800px){
    justify-content: space-between;

    ul{
      margin-bottom: 0;
    }
  }

}


h1, h2, h3, h4 {
  font-family: $f-title;
  margin: 0 0 10px;
}

h1, h2, h3, p {
  color: $c-white;

  &.preamble {
    @include font-size(2.6, 3.2);
  }
}

h1 {
  font-size:32px;
  line-height:40px;
  min-height: 0;
  font-weight: 700;
  text-transform: uppercase;
  margin:0;

  span{
    color:#00C7EB;
  }
}

h2 {
  text-transform: uppercase;
  font-weight: 700;
  margin: 20px 0 8px;
  display: block;

  &:first-child {
    margin-top: 0;
  }

  & + .form-row {
    margin-top: 5px !important;
  }
}

img{
  max-width:100%;
}

.button{
  padding:10px 30px;
  border-radius:5px;
  text-decoration: none;
  background:#00A1BE;
  color:$c-white;
  font-weight:600;
  text-transform: uppercase;
  margin-top:5px;
  display:inline-flex;
  align-items: center;
  box-shadow:0 2px 2px rgba(0,0,0,0);
  transition:all .3s ease-in-out;

  svg{
    width:15px;
    height:15px;
    margin-right:5px;
    fill:$c-white;
    display:block;
  }

  &:hover{
    background:darken(#00A1BE, 5%);
    transform:translateY(-2px);
    box-shadow:0 6px 12px rgba(0,0,0,0.3);
  }
}

header{
  padding:20px 80px 10px 20px;
  display:flex;
  justify-content: space-between;
  align-items: center;
  transition:width .5s ease-in-out;
  width:100%;


  &.settings-active{
    width:calc(100vw - 300px);
  }

  .layout{
    display:none;

    div{
      padding:6px 6px;
      background:#111;
      display:flex;
      width:30px;
      height:30px;
      margin-left:5px;
      border-radius:5px;
      transition:all .3s ease-in-out;

      &.full{
        &[data-layout='full']{
          background:#00C7EB;

          span{
            border-color:#00C7EB;
          }
        }
      }

      &.split{
        flex-wrap: wrap;

        span{
          width:50%;
          flex:none;
        }

        &[data-layout='split']{
          background:#00C7EB;

          span{
            border-color:#00C7EB;
          }
        }
      }

      &.columns{
        &[data-layout='columns']{
          background:#00C7EB;

          span{
            border-color:#00C7EB;
          }
        }
      }

      &:hover{
        background:#222;
        cursor: pointer;
      }

      &[data-active]{
        background:#00C7EB;

        span{
          border-color:#00C7EB;
        }
      }

      span{
        flex:1;
        background:#FFF;
        border:1px solid #111;
        border-radius:3px;
        transition:all .3s ease-in-out;
      }
    }
  }

  @media(min-width:800px){
    .layout{
      display: flex;
      align-items: center;
    }
  }
}


.wrapper{
  width:100vw;
  overflow-x:hidden;
  align-items:stretch;
  padding:20px 10px;
  flex-wrap: wrap;
  transition:width .5s ease-in-out;
  flex:1;


  &.settings-active{
    width:calc(100vw - 300px);
  }

  @media(min-width:800px){
    // height:calc(100vh - 115px);
    display:flex;
  }
}

.youtube-shorts{
  svg{
    fill:#FFF;
    .cls-1{fill:#fff;}.cls-2{fill:#fcfcfc;}.cls-3{fill:#fbfbfb;}.cls-4{fill:#fafafa;}.cls-5{fill:#111;}
  }
}

.chat{
  display:flex;
  flex-direction: column;
  align-items:stretch;
  padding:35px 10px 10px;
  width:100%;
  min-height:80vh;
  position:relative;
  margin-bottom:15px;

  iframe{
    flex:1;
  }

  &:only-child{
    width:100%;
  }

  .e-placeholder{
    position:absolute;
    left:10px;
    top:36px;
    background:#111;
    width:calc(100% - 20px);
    height:calc(100% - 36px);
    border-radius:10px;
    display:flex;
    justify-content: center;
    align-items: center;
    padding:20px;
    color:#888;
    border:dashed 2px #444;
    z-index:-1;

    &:not(only-child){
      display:none;
    }
  }

  .e-controls{
    position:absolute;
    top:10px;
    right:15px;
    display:flex;
    color:#888;

    span{
      font-size:16px;

      svg{
        transition:all .5s ease-in-out;
      }

      &:hover{
        color:#FFF;
        cursor: pointer;
      }
      &:last-child{
        margin-left:10px
      }
    }

  }

  .logo{
    position:absolute;
    top:0;
    left:30px;
    z-index:950;
    padding:5px 15px 5px;
    background:#111;
    border:1px solid rgba(255,255,255,0.1);
    border-bottom:none;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    box-shadow: inset 0 -3px 5px rgba(0,0,0,0.2);
    svg{
      width:auto;
      height:25px;
      display:block;
    }

    &.youtube-shorts {
      padding-top:8px;
      padding-bottom:8px;
      svg {
        height: 18px;

        .cls-5{fill:#ff0000;}
      }
    }
  }

  iframe{
    width:100%;
    border:1px solid rgba(255,255,255,0.1);
    border-radius:10px;
    box-shadow:0 5px 10px rgba(0,0,0,0.5);
    display:block;
  }
}
#twitch{
  order:1;
  position:relative;
  z-index:900;

  .logo{
    background:#6441a4;
  }

  & ~ div{
    order:2;
  }
}

#tiktok{
  .logo{
    background:#FF004F;
  }
}
#youtube, #youtube2{
  .logo{
    background:#ff0000;
  }
}

#kick{
  display:flex;
  flex-direction: column;

  .logo{
    background:#53FC18;
  }

  .inner{
    flex:1;
    display:flex;
    align-items: stretch;
    justify-content: stretch;
    background:rgba(0,0,0,0.5);
    border-radius:10px;
  }

  .user_info{
    display:none!important;
  }


}
@media(min-width:800px){
  .wrapper.full{
    .chat{
      width:100%;
    }
  }
  .wrapper.split{
    .chat{
      width:50%;
      margin:5px 0;
      min-height:0;
    }
  }
  .wrapper.columns{
    .chat{
      width:auto;
      flex:1;
      margin-bottom:0;
      min-height:0;
    }
  }
}



.settings{
  position:absolute;
  right:-300px;
  top:0;
  width:300px;
  height:100vh;
  background:#111;
  box-shadow:inset 10px 0 20px rgba(0,0,0,0.2);
  z-index:100;
  transition:all .5s ease-in-out;

  .inner-wrapper{
    overflow:auto;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    padding:30px 20px 60px;
    height:100%;
  }

  h2{
    margin-bottom:30px;
  }

  &.active{
    right:0;
  }

  .e-item{
    margin-bottom:30px;

    .logo {
      padding: 5px 15px 5px;
      display: inline-block;
      margin-left: 15px;
      background: #0b0b0b;
      border-bottom: none;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      box-shadow: inset 0 -3px 5px rgba(0, 0, 0, 0.2);

      svg {
        width: auto;
        height: 25px;
        display: block;
      }


      &.youtube-shorts {
          svg {
            height: 20px;
          }
      }
    }

    .inner{
      border-radius:10px;
      padding:15px;
      background:#0b0b0b;
      text-align:left;
      display:block;
      width:100%;
      margin-top:-8px;

      .dualfields{
        display:flex;

        div{
          &:last-child{
            margin-left:5px;
            width:100px;

            input{
              text-align: center;
              padding-left:0;

            }
          }
        }
      }

      .extras{
        display:flex;
        justify-content: space-between;
        margin-top:10px;

        .item{
          display:flex;
          align-items: center;
          font-size:14px;

          &:hover{
            cursor: pointer;

            .icons{
              border-color:#00C7EB;
            }
          }

          .icon{
            margin:-1px 5px 0 0;
            width:20px;
            height:20px;
            border:1px solid #111;
            display:flex;
            align-items: center;
            justify-content: center;
            border-radius:5px;
            background:#888;

            &.active{
              background:#00C7EB;
              color:#111;
            }
          }
        }
      }

      label{
        margin:0 0 0 5px;
        padding:0 10px;
        background:#0b0b0b;
        position:relative;
        z-index:5;
        font-size:10px;
        display:inline-block;
      }
      span{
        &.link{
          display:block;
          text-align: left;
          a{
            color:#00C7EB;
            font-size:12px;
          }
        }
      }

      .e-enable{
        position:relative;
        display:inline-flex;
        align-items: center;
        margin-bottom:5px;

        &:hover{
          cursor: pointer;
          &:before{
            box-shadow:inset 0 0 5px rgba(0,0,0,0.2), 0 0 5px rgba(0,0,0,0.3);
            background:#333;
          }
        }

        &.enabled{
          &:after{
            background:#00C7EB;
          }

          &:before{
            left:68px;
          }
        }

        &:before, &:after{
          content:'';
          display:block;
          transition:all .5s ease-in-out;
        }

        &:after{
          width:35px;
          height:20px;
          border-radius:30px;
          background:#ff0000;
          margin-left:10px;
        }
        &:before{
          position:absolute;
          width:16px;
          height:16px;
          border-radius:50%;
          background:#111;
          top:50%;
          transform:translateY(-50%);
          left:82px;
          box-shadow:inset 0 0 5px rgba(0,0,0,0.2), 0 0 5px rgba(0,0,0,0.0);
          border:1px solid #333;

        }
      }

      input{
        border:1px solid rgba(255,255,255,0.2);
        width:100%;
        padding:12px 15px 10px;
        background:rgba(0,0,0,0.3);
        border-radius:5px;
        color:#FFF;
        transition:all .3s ease-in-out;
        display:block;
        margin-top:-12px;

        &:hover, &:focus{
          border-color:#00C7EB;
        }
      }
    }
  }
  .buttons{
    display:flex;
    margin-top:auto;
    left:0;
    bottom:0;
    width:100%;
    box-sizing: border-box;
    position:absolute;
    padding:20px;
    background:#111111;
    z-index:80;
    border-top:1px solid rgba(0,0,0,0.5);

    button{
      -webkit-appearance:none;
      padding:10px 25px;
      flex:1;
      border-radius:5px;
      border:1px solid darken(#00C7EB, 15%);
      background:darken(#00C7EB, 15%);
      color:#FFF;
      box-shadow:0 5px 15px rgba(0,199,235,0.3);
      transition:all .3s ease-in-out;
      &:hover{
        background:lighten(#00C7EB, 5%);
        box-shadow:0 5px 30px rgba(0,199,235,0.6);
      }

      &.secondary{
        border:1px solid #888;
        color:#888;
        background:transparent;
        margin-right:20px;
        box-shadow:0 5px 15px rgba(255,255,255,0);

        &:hover{
          color:#FFF;
          border-color:#FFF;
          box-shadow:0 5px 15px rgba(255,255,255,0.2);
        }
      }



    }

  }

  .e-tag{
    position:absolute;
    top:10px;
    right:100%;
    padding:20px;
    border-top-left-radius:20px;
    border-bottom-left-radius:20px;
    background:#111;
    color:#fff;
    font-size:20px;
    transition:all 0.5s ease-in-out;

    &:hover{
      color:#00C7EB;
      cursor: pointer;
      padding:20px 30px 20px 20px;
    }
    svg{
      display:block;
    }
  }

  @media(min-width:800px){
    height:calc(100vh - 45px);

  }
}

.message_content{
  font-size:14px!important;
}

.e-promobanner{
  padding:10px 20px;
  background:#00C7EB;
  font-weight:500;
  text-align: center;
  position:relative;
  color:#333;

  a{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
  }

  .e-close{
    position:absolute;
    top:50%;
    transform:translateY(-50%);
    right:20px;
    font-size:20px;
    width:26px;
    height: 26px;
    border-radius:50%;
    display:flex;
    align-items: center;
    justify-content: center;
    background:#333;
    color:#00C7EB;
    border:2px solid #333;
    transition:all .3s ease-in-out;

    &:hover{
      color:#333;
      background:#00C7EB;
      cursor:pointer;
    }
  }
}